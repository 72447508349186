import {
  findUserAccountAmount,
  findCurrencyType
} from '../ForeignExchangeTransactions/ForeignExchangeTransactionsApi'
import {
  thousandthConversion
} from '../commonMethods'
import {
  findUserTotalAmount,
  findCnyAssetTrendByAcid
} from './AccountInformationApi'
export const information = async (data) => {
  let param = {
    acId: JSON.parse(sessionStorage.getItem("acid")),
  }
  let findCurrencyTypeRes = await findCurrencyType();
  let newRes = findCurrencyTypeRes.data
  if (newRes.code == 200) {
    data.currencyData = newRes.data.currencyData;
  }
  findUserAccountAmount(param).then((res) => {
    let newRes = res.data
    if (newRes.code == 200) {
      data.userAccountAmount = newRes.data.userAccountAmount
      newRes.data.userAccountAmount.forEach(item => {
        item.amountss = thousandthConversion(item.amounts)
        item.freeAmounts = thousandthConversion(item.freeAmount)
        item.frozenAmounts = thousandthConversion(item.frozenAmount)
        data.currencyData.forEach(every => {
          if (every.id === item.currencyType) {
            item.currencyType = every.name
          }
        })
      });
    }
    sessionStorage.setItem("accountAssets", JSON.stringify(data.userAccountAmount))
  })
}

//币种余额转换
export const balanceConversion = (data) => {
  let params = {
    acId: JSON.parse(sessionStorage.getItem("acid"))
  }
  findUserTotalAmount(params).then((res) => {
    let newRes = res.data
    if (newRes.code == 200) {
      let cnyTotalAmount = thousandthConversion(newRes.data.cnyTotalAmount)
      let usdTotalAmount = thousandthConversion(newRes.data.usdTotalAmount)
      data.cnyTotalAmount = cnyTotalAmount
      data.usdTotalAmount = usdTotalAmount
      data.loading = false
    }
  })
}

// 净值图

export const netWorthChart = (data) => {
  let date = [];
  let value = [];
  let params = {
    acId: JSON.parse(sessionStorage.getItem("acid"))
  }
  findCnyAssetTrendByAcid(params).then(res => {
    let arr = res.data.data.forexUserAccountAmounts
    if (res.data.code == 200) {
      for (let i in arr) {
        date.push(arr[i].transactionDate)
        value.push(arr[i].amount)
      }
      data.option = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: date,
        },
        yAxis: {
          type: "value",
        },
        grid: {
          left: '18%', // 左侧数值被遮挡调整这个属性
        },
        series: [{
          data: value,
          type: "line",
          smooth: true,
          lineStyle: {
            color: "#FEAE10",
          },
          itemStyle: {
            color: "#FEAE10",
          },
        }, ],
      };
    }

  })

}